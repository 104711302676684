import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { usePagination } from '../../../hooks/usePagination';
import PropTypes from 'prop-types';
import { getDossiersMaPrimeRenov, getDossiersMaPrimeRenovCount } from '../../../../store/services/dossier-maPrimeRenov.service';
import { executeAnyTypeOfRequest } from '../../../../store/actions/ui/form.actions';

export const MaPrimeRenovTableWrapper = ({ maPrimeRenovTableRender }) => {
    const dispatch = useDispatch();
    const [dossiersMaPrimeRenov, setDossiersMaPrimeRenov] = useState([]);

    const formatMaPrimeRenov = (maPrimeRenov) => ({
        ...maPrimeRenov,
        customerFullName: maPrimeRenov?.quotation?.customerData?.customerFullName,
        operations: maPrimeRenov?.operations?.map((operation) => ({ name: operation })),
        representativeType: maPrimeRenov?.quotation?.renovationPremium?.representativeType,
    });

    const fetchDossiersMaPrimeRenov = useCallback(
        (query = {}) => {
            const fetchGetDossiersMaPrimeRenov = async () => getDossiersMaPrimeRenov({ ...query });
            dispatch(executeAnyTypeOfRequest(fetchGetDossiersMaPrimeRenov)).then((data) => {
                if (!data) return setDossiersMaPrimeRenov([]);
                setDossiersMaPrimeRenov(data.map(formatMaPrimeRenov));
            });
        },
        [dispatch]
    );

    const { onPaginationChange, paginate, setSort, onItemsCountPerPageChangeCallback } = usePagination(getDossiersMaPrimeRenovCount, fetchDossiersMaPrimeRenov, {
        defaultCountQuery: {},
        defaultRequestQuery: {},
    });

    return maPrimeRenovTableRender({
        dossiersMaPrimeRenov: dossiersMaPrimeRenov,
        onPaginationChange,
        paginate,
        onItemsCountPerPageChangeCallback,
        setSort,
    });
};

MaPrimeRenovTableWrapper.propTypes = {
    maPrimeRenovTableRender: PropTypes.func.isRequired,
};
