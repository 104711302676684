import React, { useState } from 'react';
import { DossierMaPrimeRenovTable } from '../../core/maPrimeRenov/components/DossierMaPrimeRenovTable';
import { CardTabs } from '@renolib/renolib-ui-kit';
import { MaPrimeRenovTableWrapper } from '../../core/maPrimeRenov/components/MaPrimeRenovTableWrapper';
import { ReactComponent as MaPrimeRenovEncartInformation } from '../../../assets/images/others/maPrimeRenovEncartInformationVersionB.svg';

import styled from 'styled-components';
import { Icon } from '../../helpers/Icon';
import colors from '../../../config/colors';

const MaPrimeRenovEncart = ({ className }) => {
    const openStatusEnums = { OPEN: 'OPEN', CLOSE: 'CLOSED' };
    const [openStatus, setOpenStatus] = useState(openStatusEnums.OPEN);

    return (
        <div className={`${className} ${openStatus.toLowerCase()}`} onClick={() => setOpenStatus(openStatus === openStatusEnums.OPEN ? openStatusEnums.CLOSE : openStatusEnums.OPEN)}>
            <div>
                <Icon size='lg' name={'info'} color='primary' style={{ display: 'inline-block', fontWeight: 'bold', fontSize: '20px', color: colors.primaryDark }} />
                <span>Suivez la progression de vos dossiers MaPrimeRénov&apos;</span>
            </div>
            <MaPrimeRenovEncartInformation />
        </div>
    );
};

const StyledMaPrimeRenovEncart = styled(MaPrimeRenovEncart)`
        position: relative;
          svg {
            width: 100%;
            height: 97%;
          }
          &.open {
            > div {
              position: absolute;
              justify-content: center;
            }
          }

          
  
          > div {
            position: absolute;
            cursor:pointer;
            top: 15%;
            left: 50%;
            display:flex;
            align-items: center;
            transform: translate(-50%, -50%);
          }

          &.closed {
            svg {
              width: 100%;
              opacity: 0;
              height:150px;
            }
            > div {
              position: absolute;
              top: 50.1%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          span:first-child {
            cursor: pointer;
          }
        
          span:last-child {
            color: ${colors.primaryDark};
            font-weight: bold;
            font-size: 14px;
            margin-left: 10px;
          }
            }
`;

const MaPrimeRenovTableContainer = () => {
    return (
        <div style={{ padding: '0rem 2.5rem 0 2.5rem' }}>
            <CardTabs>
                <CardTabs.Item title='Tous mes dossiers' tabKey='all-dossiers-cee'>
                    <MaPrimeRenovTableWrapper
                        defaultRequestQuery={{}}
                        maPrimeRenovTableRender={({ dossiersMaPrimeRenov, setSort, onItemsCountPerPageChangeCallback, paginate, onPaginationChange }) => {
                            return (
                                <DossierMaPrimeRenovTable
                                    dossiersMaPrimeRenov={dossiersMaPrimeRenov}
                                    onItemsCountPerPageChangeCallback={onItemsCountPerPageChangeCallback}
                                    paginate={paginate}
                                    onPaginationChange={onPaginationChange}
                                    onSort={setSort}
                                />
                            );
                        }}
                    />
                </CardTabs.Item>
            </CardTabs>
        </div>
    );
};

const DossiersMaPrimeRenov = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledMaPrimeRenovEncart />
            <MaPrimeRenovTableContainer />
        </div>
    );
};

export default DossiersMaPrimeRenov;
