export const GET_DELEGATEE_INVOICE_REQUEST = 'GET_DELEGATEE_INVOICE_REQUEST';
export const GET_DELEGATEE_INVOICE_SUCCESS = 'GET_DELEGATEE_INVOICE_SUCCESS';
export const GET_DELEGATEE_INVOICE_FAILURE = 'GET_DELEGATEE_INVOICE_FAILURE';

export const GET_DELEGATEE_INVOICES_REQUEST = 'GET_DELEGATEE_INVOICES_REQUEST';
export const GET_DELEGATEE_INVOICES_SUCCESS = 'GET_DELEGATEE_INVOICES_SUCCESS';
export const GET_DELEGATEE_INVOICES_FAILURE = 'GET_DELEGATEE_INVOICES_FAILURE';

export const VALIDATE_DELEGATEE_INVOICE_REQUEST = 'VALIDATE_DELEGATEE_INVOICE_REQUEST';
export const VALIDATE_DELEGATEE_INVOICE_SUCCESS = 'VALIDATE_DELEGATEE_INVOICE_SUCCESS';
export const VALIDATE_DELEGATEE_INVOICE_FAILURE = 'VALIDATE_DELEGATEE_INVOICE_FAILURE';

export const SEND_DELEGATEE_INVOICE_REQUEST = 'SEND_DELEGATEE_INVOICE_REQUEST';
export const SEND_DELEGATEE_INVOICE_SUCCESS = 'SEND_DELEGATEE_INVOICE_SUCCESS';
export const SEND_DELEGATEE_INVOICE_FAILURE = 'SEND_DELEGATEE_INVOICE_FAILURE';
