import { TableFooter } from '@renolib/renolib-ui-kit';
import React from 'react';
import { paginatePropTypes } from '../../../utils/propTypeSchemas';
import PropTypes from 'prop-types';

export const PaginationTableFooter = ({ paginate, onPaginationChange }) => {
    return (
        <>
            {paginate?.count > paginate?.limit && (
                <TableFooter
                    currentItemsCountPerPage={paginate?.limit}
                    currentPage={paginate?.currentPage}
                    totalItemsCount={paginate?.count}
                    pagesCount={Math.ceil(paginate?.count / paginate?.limit)}
                    onPageChange={onPaginationChange}
                />
            )}
        </>
    );
};

PaginationTableFooter.propTypes = {
    onPaginationChange: PropTypes.func,
    paginate: paginatePropTypes,
};
