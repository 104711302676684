import React, { Fragment, useMemo } from 'react';
import { Popover, Status } from '@renolib/renolib-ui-kit';
import { Icon } from '../../../helpers/Icon';
import styled from 'styled-components';
import {
    AccountCreationProcessing,
    CurrentlyInstructByAnah,
    PaidByANAH,
    PaidByRenolib,
    ProcessingControl,
    ProcessingPaimentByRenolib,
    RefusedMandate,
    RequestBalance,
    RequestRefuseByAnah,
    RequestValidatedByAnah,
    WaitingForSignedQuotation,
} from './maPrimeRenovTooltipContent';
import PropTypes from 'prop-types';

const getContent = (status) => {
    const getTooltip = (status) => {
        const allMaPrimeRenovStatusTooltips = {
            'En attente du devis signé': () => <WaitingForSignedQuotation />,
            'Création du compte en cours': () => <AccountCreationProcessing />,
            'Mandat refusé': () => <RefusedMandate />,
            "En cours d'instruction par l'ANAH": () => <CurrentlyInstructByAnah />,
            "Demande validée par l'ANAH": () => <RequestValidatedByAnah />,
            'Contrôle en cours': () => <ProcessingControl />,
            'RÉNOLIB prépare votre paiement': () => <ProcessingPaimentByRenolib />,
            'Payé par RÉNOLIB': () => <PaidByRenolib />,
            'Demande de solde réalisée': () => <RequestBalance />,
            'Demande refusée': () => <RequestRefuseByAnah />,
            "Payé par l'ANAH": () => <PaidByANAH />,
        };
        const tooltip = allMaPrimeRenovStatusTooltips[status];
        return tooltip ? tooltip() : <Fragment />;
    };
    return <>{getTooltip(status)}</>;
};

const StatusPopOver = (props) => {
    return (
        <Popover style={{ marginLeft: '3px' }} render={() => getContent(props.status)} placement={props.placement || 'right'} adjustPositioningConstant={10} isArrowCentered={true} {...props}>
            <Icon size='xs' name={'info'} color='primary' style={{ display: 'inline-block', fontSize: '16px', color: props.color }} />
        </Popover>
    );
};

const UnStyledMaPrimeRenovStatus = ({ className, status, placement }) => {
    const getStatusConfig = {
        'Création du compte en cours': { color: '#757A8C', placement: placement || 'left', adjustPositioningConstant: 7.5 },
        "En cours d'instruction par l'ANAH": { color: '#BA9839', placement: placement || 'left', adjustPositioningConstant: 11 },
        "Demande validée par l'ANAH": { color: '#357CCE', placement: placement || 'left', adjustPositioningConstant: 9.5 },
        'Demande de solde réalisée': { color: '#8C76C9', placement: placement || 'left', adjustPositioningConstant: 7 },
        'RÉNOLIB prépare votre paiement': { color: '#8C76C9', placement: placement || 'left', adjustPositioningConstant: 5 },
        'En attente du devis signé': { color: '#74798c', placement: placement || 'left', adjustPositioningConstant: 5 },
        'Contrôle en cours': { color: '#3E60AC', placement: placement || 'left', adjustPositioningConstant: 11.5 },
        'Mandat refusé': { color: '#E46526', placement: placement || 'left', adjustPositioningConstant: 4.5 },
        'Demande refusée': { color: '#E46526', placement: placement || 'left', adjustPositioningConstant: 12 },
        'Payé par RÉNOLIB': { color: '#6CBCC9', placement: placement || 'left', adjustPositioningConstant: 4.5 },
        "Payé par l'ANAH": { color: '#6CBCC9', placement: placement || 'left', adjustPositioningConstant: 4.5 },
    };

    const formatStatus = (status) => {
        const search = '’';
        const searchRegExp = new RegExp(search, 'g'); // Throws SyntaxError
        const replaceWith = "'";

        if (!status) return;

        return status.replace(searchRegExp, replaceWith);
    };

    const formattedStatus = useMemo(() => formatStatus(status), [status]);

    const statusConfig = useMemo(() => {
        if (!formattedStatus || formattedStatus === 'TODO') return { color: '', placement: 'left', adjustPositioningConstant: 2 };
        const statusConfig = getStatusConfig[formattedStatus];
        return { ...statusConfig, color: statusConfig?.color };
    }, [formattedStatus, getStatusConfig]);

    return (
        <div className={`${className}`}>
            <Status status={formattedStatus}>{formattedStatus}</Status>
            <StatusPopOver
                popoverWidth={'13rem'}
                color={statusConfig.color}
                adjustPositioningConstant={14}
                status={formattedStatus}
                style={{ cursor: 'pointer', marginLeft: '7px' }}
                {...statusConfig}
            />
        </div>
    );
};

UnStyledMaPrimeRenovStatus.propTypes = {
    status: PropTypes.string,
    placement: 'left' | 'right' | 'top' | 'bottom',
    statusWidth: PropTypes.string,
};

export const MaPrimeRenovStatus = styled(UnStyledMaPrimeRenovStatus)`
    display: flex;
    flex-direction: row;
    align-items: center;

    > div:first-of-type {
        width: 70%;
        justify-content: center;
        text-align: center;
    }
`;
