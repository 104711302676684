import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import reducers from './reducers';

const store = createStore(reducers, composeWithDevTools(applyMiddleware(...getMiddlewares())));

function getMiddlewares() {
    const middlewares = [reduxThunk];
    const reduxLogger = createLogger({
        collapsed: (getState, action, logEntry) => !logEntry.error,
    });
    if (process.env.NODE_ENV === 'development') middlewares.push(reduxLogger);

    return middlewares;
}

export default store;
