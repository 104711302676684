import { useState, useEffect, useCallback } from 'react';

export const usePagination = (getCountFunction, getEntities, { defaultCountQuery, defaultRequestQuery, searchString, defaultCount = 25 }) => {
    const [localSearchString, setLocalSearchString] = useState(searchString);
    const [sort, setSort] = useState({ createdAt: -1 });
    const [functionCallsQuery] = useState({
        defaultRequestQuery,
        defaultCountQuery,
    });

    const [paginate, setPaginate] = useState({
        limit: defaultCount,
        skip: 0,
        count: 0,
        currentPage: 1,
    });

    const onPaginationChange = ({ page }) => {
        setPaginate({
            ...paginate,
            skip: page * paginate.limit - paginate.limit,
            currentPage: page,
        });
    };

    const onItemsCountPerPageChangeCallback = (newLimit) => {
        setPaginate({
            ...paginate,
            limit: newLimit,
            skip: 0,
            currentPage: 1,
        });
    };

    const fetchEntities = useCallback(
        (query = {}) => {
            const paginateQuery = { paginate: JSON.stringify({ limit: paginate.limit, skip: paginate.skip }) };
            getEntities({ searchString: localSearchString, ...query, ...functionCallsQuery.defaultRequestQuery, ...paginateQuery, sort: JSON.stringify(sort) });
        },
        [paginate, functionCallsQuery.defaultRequestQuery, getEntities, localSearchString, sort]
    );

    const fetchCount = useCallback(
        (query = {}) => {
            getCountFunction({ ...query, ...functionCallsQuery.defaultCountQuery, searchString: localSearchString })
                .then(({ data }) => {
                    setPaginate((currentPagination) => ({ ...currentPagination, count: data }));
                })
                .catch(() => {
                    setPaginate((currentPagination) => ({ ...currentPagination, count: 0 }));
                });
        },
        [getCountFunction, functionCallsQuery.defaultCountQuery, localSearchString]
    );

    useEffect(() => {
        setLocalSearchString(searchString);
    }, [searchString]);

    useEffect(() => {
        fetchCount();
    }, [fetchCount, localSearchString]);

    useEffect(() => {
        fetchEntities();
    }, [fetchEntities, paginate.skip, localSearchString]);

    return {
        paginate,
        onPaginationChange,
        setSort,
        onItemsCountPerPageChangeCallback,
    };
};
