import { Icon } from '@renolib/renolib-ui-kit';
import React from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';

import useMessage from '../hooks/useMessage';

const Message = ({ className }) => {
    const { handleClose } = useMessage();

    const { messageState } = useSelector((state) => state.ui.message);
    const { shown, type, message } = messageState;

    if (!shown) return null;

    return (
        <div className={`${className} ${type} show`}>
            <span>{message}</span>
            <div onClick={handleClose} aria-hidden='true'>
                <Icon name='close' color='#E7E7E7' size='sm' />
            </div>
        </div>
    );
};

const showSlide = keyframes`
  0% {
    transform: translateX(100%);
  }

  40% {
    transform: translateX(-10%);
  }

  80% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-2%);
  }
`;

const StyledMessage = styled(Message)`
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    right: 0;
    top: 8px;
    padding: 1.2rem;
    border-radius: var(--border-radius);
    width: 434px;
    z-index: 999;
    overflow: hidden;

    > div {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 1.2rem 10px;
        cursor: pointer;
    }

    &.show {
        animation: ${showSlide} 1s ease forwards;
    }

    &.error {
        border-left: 8px solid #f53a6b;
        background-color: #fb91ad;

        > div {
            background-color: #f53a6b;
            color: var(--white-color);
        }

        > span {
            color: #f53a6b;
            font-weight: 600;
        }
    }

    &.success {
        border-left: 8px solid #4ebc9d;
        background-color: #adecdb;

        > div {
            background-color: #4ebc9d;
            color: var(--white-color);
        }

        > span {
            color: #4ebc9d;
            font-weight: 600;
        }
    }
`;

export default StyledMessage;
