import { GET_RESTRICTED_OPERATIONS_FAILURE, GET_RESTRICTED_OPERATIONS_SUCCESS } from '../types/operations.types';

const initialState = {
    restrictedOperations: [],
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_RESTRICTED_OPERATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                restrictedOperations: payload,
            };
        case GET_RESTRICTED_OPERATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                restrictedOperations: [],
            };
        default:
            return state;
    }
};
