import axios from 'axios';

const setToken = (token) => {
    if (token) {
        axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    } else {
        axios.defaults.headers.common = { Authorization: null };
    }
};

export default setToken;
