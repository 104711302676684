import { GET_CANCELLED_DOCUMENT_SUCCESS, GET_CANCELLED_DOCUMENT_FAILURE } from '../types/cancelledDocument.type';

const initialState = {
    cancelledDocument: {},
    loading: true,
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_CANCELLED_DOCUMENT_SUCCESS:
            return {
                ...state,
                cancelledDocument: payload,
                loading: false,
            };
        case GET_CANCELLED_DOCUMENT_FAILURE:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        default:
            return state;
    }
};
