import React from 'react';
import PropTypes from 'prop-types';
import { Table, Text } from '@renolib/renolib-ui-kit';
import { MaPrimeRenovStatus } from './MaPrimeRenovStatus';
import Operations from '../../../helpers/Operations';
import { PaginationTableFooter } from '../../tables/PaginationTableFooter';
import { getValueOrTheSpecifiedValueIfUndefined } from '../../../../utils/entities.utils';
import * as entitiesUtils from '../../../../utils';
import { applicationPagePropType, invoicePropType, quotationPropTypes } from '../../../../utils/propTypeSchemas';
import { representativeTypeTranslation } from '../../../../utils/enums';

export const DossierMaPrimeRenovTable = ({ dossiersMaPrimeRenov, noDataMessage, onItemsCountPerPageChangeCallback, paginate, onPaginationChange, onSort }) => {
    return (
        <Table
            columns={[
                {
                    title: 'Raison sociale',
                    key: 'name',
                    dataIndex: 'quotation',
                    sortable: true,
                    render: (quotation) => <Text.Label>{quotation.organization.name}</Text.Label>,
                },
                {
                    title: 'Numéro du dossier',
                    key: 'mprFolderId',
                    dataIndex: 'mprFolderId',
                    sortable: true,
                    render: (mprFolderId) => {
                        return <Text color='primary'>{`${getValueOrTheSpecifiedValueIfUndefined(mprFolderId)}`}</Text>;
                    },
                },
                {
                    title: 'Nom du client',
                    key: 'customerName',
                    dataIndex: 'quotation',
                    sortable: true,
                    render: (quotation) => <Text.Label>{quotation.customer.customerData.fullName}</Text.Label>,
                },
                {
                    title: 'Date du devis',
                    key: 'quotationIssueDate',
                    dataIndex: 'quotation',
                    sortable: true,
                    render: (quotation) => <Text.Label>{entitiesUtils.formatDate(quotation?.quotationIssueDate)}</Text.Label>,
                },
                {
                    title: 'Opérations',
                    key: 'operations',
                    dataIndex: 'operations',
                    sortable: true,
                    render: (operations) => <Operations operations={operations} />,
                },
                {
                    title: 'Mandat',
                    key: 'mandate',
                    dataIndex: 'representativeType',
                    sortable: true,
                    render: (representativeType) => <Text.Label>{representativeTypeTranslation[representativeType]}</Text.Label>,
                },
                {
                    title: 'Prime estimée',
                    key: 'anahEstimatedAmount',
                    dataIndex: 'quotation',
                    sortable: true,
                    render: (quotation) => <Text.Label>{entitiesUtils.formatPrice(quotation?.renovationPremium?.maPrimeRenovTotalAmount)}</Text.Label>,
                },
                {
                    title: 'Prime confirmée',
                    key: 'anahValidatedAmount',
                    dataIndex: 'anahValidatedAmount',
                    sortable: true,
                    render: (anahValidatedAmount) => <Text.Label>{entitiesUtils.formatPrice(anahValidatedAmount)}</Text.Label>,
                },
                {
                    title: 'Statut',
                    key: 'status',
                    dataIndex: 'status',
                    sortable: true,
                    render: (status) => <MaPrimeRenovStatus status={status} />,
                },
            ]}
            dataSource={dossiersMaPrimeRenov}
            defaultCurrentItemsCountPerPage={20}
            onItemsCountPerPageChangeCallback={onItemsCountPerPageChangeCallback}
            shouldComponentHandleItemsNumber={false}
            noDataMessage={noDataMessage}
            renderFooter={() => <PaginationTableFooter paginate={paginate} onPaginationChange={onPaginationChange} />}
            onSort={(dataIndex, value) => onSort({ [`${dataIndex}`]: value })}
        />
    );
};

DossierMaPrimeRenovTable.propTypes = {
    targetPage: applicationPagePropType,
    dossiersMaPrimeRenov: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            number: PropTypes.string,
            acceptationLetter: PropTypes.string,
            domiciliationCertification: PropTypes.string,
            grantLetterUrl: PropTypes.string,
            invoice: invoicePropType,
            quotation: quotationPropTypes,
            remittanceLetterUrl: PropTypes.string,
            signedMandateUrl: PropTypes.string,
            status: PropTypes.string,
            anahHtmlProof: PropTypes.string,
            refQuotationStatus: PropTypes.string,
            isSienna: PropTypes.bool,
            isTestEntry: PropTypes.bool,
            anahValidatedAmount: PropTypes.number,
            operations: PropTypes.arrayOf(PropTypes.string),
        })
    ),
    noDataMessage: PropTypes.string,
    loading: PropTypes.bool,
    onSort: PropTypes.func,
};
