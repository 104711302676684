import { useDispatch } from 'react-redux';

import messageActions from '../../store/actions/ui/message.action';

export default () => {
    const dispatch = useDispatch();

    const handleClose = () => dispatch(messageActions.closeMessage());

    return { handleClose };
};
