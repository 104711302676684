import { isNonEmptyObject } from '../../utils';
import {
    GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_SUCCESS,
    GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_FAILURE,
    GET_ORGANIZATION_DOCUMENT_FAILURE,
    GET_ORGANIZATION_DOCUMENT_SUCCESS,
    GET_ORGANIZATIONS_SUCCESS,
    GET_ORGANIZATIONS_FAILURE,
    GET_ORGANIZATION_SUCCESS,
    GET_ORGANIZATION_FAILURE,
    GET_ORGANIZATION_REQUEST,
    GET_INSTALLER_REQUEST,
    GET_INSTALLER_SUCCESS,
    GET_INSTALLER_FAILURE,
    UPDATE_ORGANIZATION_REQUEST,
    UPDATE_ORGANIZATION_SUCCESS,
    UPDATE_ORGANIZATION_FAILURE,
    SET_ACTIVE_ORGANIZATIONS,
    SET_INACTIVE_ORGANIZATIONS,
    ENABLE_ORGANIZATION_EXTENSION_SUCCESS,
    DISABLE_ORGANIZATION_EXTENSION_SUCCESS,
} from '../types/organization.type';

const initialState = {
    organizationsWithTheirContractsAndSubContractors: [],
    organizations: [],
    document: {},
    currentUserOrganization: null,
    organization: null,
    loading: true,
    activeOrganizations: [],
    inactiveOrganizations: [],
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_SUCCESS:
            return {
                ...state,
                organizationsWithTheirContractsAndSubContractors: payload,
            };
        case GET_ORGANIZATION_DOCUMENT_SUCCESS:
            return {
                ...state,
                document: payload,
                loading: false,
            };
        case GET_ORGANIZATIONS_SUCCESS:
            return {
                ...state,
                organizations: payload,
            };
        case GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_FAILURE:
            return {
                ...state,
                organizationsWithTheirContractsAndSubContractors: [],
            };
        case GET_ORGANIZATION_DOCUMENT_FAILURE:
            return {
                ...state,
                document: {},
                loading: false,
            };
        case GET_ORGANIZATIONS_FAILURE:
            return {
                ...state,
                organizations: [],
            };
        case GET_ORGANIZATION_REQUEST:
            return {
                ...state,
                currentUserOrganization: null,
                loading: true,
            };
        case GET_ORGANIZATION_FAILURE: {
            return {
                ...state,
                currentUserOrganization: null,
            };
        }
        case GET_ORGANIZATION_SUCCESS:
            return {
                ...state,
                loading: false,
                currentUserOrganization: action.payload,
            };
        case UPDATE_ORGANIZATION_REQUEST:
        case GET_INSTALLER_REQUEST:
            return {
                ...state,
                loading: false,
                organization: action.payload,
            };
        case UPDATE_ORGANIZATION_SUCCESS:
        case GET_INSTALLER_SUCCESS:
            return {
                ...state,
                loading: false,
                organization: action.payload,
            };
        case UPDATE_ORGANIZATION_FAILURE:
        case GET_INSTALLER_FAILURE:
            return {
                ...state,
                loading: false,
                organization: action.payload,
            };

        case SET_ACTIVE_ORGANIZATIONS:
            return {
                ...state,
                activeOrganizations: payload.organizations,
            };

        case SET_INACTIVE_ORGANIZATIONS:
            return {
                ...state,
                inactiveOrganizations: payload.organizations,
            };

        case ENABLE_ORGANIZATION_EXTENSION_SUCCESS: {
            const organization = state.inactiveOrganizations.find((inactiveOrganization) => inactiveOrganization.id === payload.organization.id);
            const activeOrganizations = [...state.activeOrganizations];
            if (isNonEmptyObject(organization)) activeOrganizations.push({ ...payload.organization, ...organization });

            return {
                ...state,
                activeOrganizations,
                inactiveOrganizations: state.inactiveOrganizations.filter((inactiveOrganization) => {
                    return inactiveOrganization.id !== payload.organization.id;
                }),
            };
        }

        case DISABLE_ORGANIZATION_EXTENSION_SUCCESS: {
            const organization = state.activeOrganizations.find((activeOrganization) => activeOrganization.id === payload.organization.id);
            const inactiveOrganizations = [...state.inactiveOrganizations];
            if (isNonEmptyObject(organization)) inactiveOrganizations.push({ ...payload.organization, ...organization });

            return {
                ...state,
                inactiveOrganizations,
                activeOrganizations: state.activeOrganizations.filter((activeOrganization) => {
                    return activeOrganization.id !== payload.organization.id;
                }),
            };
        }
        default:
            return state;
    }
};
