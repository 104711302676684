import PropTypes from 'prop-types';

export const insurancePropType = PropTypes.shape({
    reference: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    warrantyArea: PropTypes.string,
    uuid: PropTypes.string,
    status: PropTypes.string,
    streetNumber: PropTypes.string,
    streetName: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
    startDate: PropTypes.string,
    fileDownloadUrl: PropTypes.string,
});

export const rgePropType = PropTypes.shape({
    reference: PropTypes.string,
    uuid: PropTypes.string,
    status: PropTypes.string,
    fileDownloadUrl: PropTypes.string,
    remoteName: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    domains: PropTypes.arrayOf(PropTypes.string),
});

export const kbisPropType = PropTypes.shape({
    fileDownloadUrl: PropTypes.string,
    startDate: PropTypes.string,
    uuid: PropTypes.string,
    status: PropTypes.string,
});

export const organizationProptype = PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
    commercialName: PropTypes.string,
    type: PropTypes.string,
    capital: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    naf: PropTypes.string,
    ape: PropTypes.string,
    siret: PropTypes.number,
    vat: PropTypes.string,
    organizationAddresses: PropTypes.arrayOf(
        PropTypes.shape({
            isMainAddress: PropTypes.bool,
            streetNumber: PropTypes.string,
            streetName: PropTypes.string,
            postalCode: PropTypes.string,
            city: PropTypes.string,
        })
    ),
    organizationBilling: PropTypes.shape({
        phone: PropTypes.string,
        email: PropTypes.string,
        advancePayment: PropTypes.number,
        orderBy: PropTypes.string,
        bic: PropTypes.string,
        iban: PropTypes.string,
        paymentDelay: PropTypes.number,
        latePenalties: PropTypes.number,
        recoveryFees: PropTypes.number,
    }),
    organizationRGECertificates: PropTypes.arrayOf(rgePropType),
    organizationInsurances: PropTypes.arrayOf(insurancePropType),
});

export const pricesPropType = PropTypes.arrayOf(
    PropTypes.shape({
        amount: PropTypes.number,
        label: PropTypes.string,
        uuid: PropTypes.string,
    })
);

export const coupDePouceOperationsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        type: PropTypes.shape,
        operationSheetName: PropTypes.string,
        operationName: PropTypes.string,
    })
);

export const contractPropType = PropTypes.shape({
    uuid: PropTypes.string,
    status: PropTypes.string,
    billingAddress: PropTypes.string,
    billingEmail: PropTypes.string,
    daysOfPaymentDelay: PropTypes.number,
    delegateeName: PropTypes.string,
    endDate: PropTypes.string,
    mandatoryDetails: PropTypes.string,
    prices: pricesPropType,
    reference: PropTypes.string,
    signatoryFirstName: PropTypes.string,
    signatoryName: PropTypes.string,
    signatureDate: PropTypes.string,
    startDate: PropTypes.string,
    coupDePouceOperations: coupDePouceOperationsPropType,
});

export const amendmentPropType = PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    prices: pricesPropType,
    uuid: PropTypes.string,
    reference: PropTypes.string,
    mandatoryDetails: PropTypes.string,
});
export const organizationsWithTheirContractsAndSubContractorsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        contracts: PropTypes.arrayOf(contractPropType),
        organization: organizationProptype,
    })
);

export const inputValuePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const dossierCEEPropType = PropTypes.shape({
    uuid: PropTypes.string,
    number: PropTypes.string,
    status: PropTypes.string,
    sendingDate: PropTypes.string,
    sendingDateForBackOfficeVerification: PropTypes.string,
    recipientEmail: PropTypes.string,
    quotation: PropTypes.shape({}),
    invoice: PropTypes.shape({}),
    postRenovationReport: PropTypes.shape({}),
    uploadDocuments: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
    organization: PropTypes.shape({}),
});

export const dossiersCEEPropType = PropTypes.arrayOf(dossierCEEPropType);

export const dossierCEEDocumentPropType = PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    status: PropTypes.string,
    fileType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    fileDownloadUrl: PropTypes.string,
});

export const modalPropTypes = {
    shown: PropTypes.bool,
    formState: PropTypes.shape({}),
    formErrors: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
};

export const operationsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
    })
);

export const quotationPropTypes = PropTypes.shape({
    quotationNumber: PropTypes.string,
    customer: PropTypes.shape({
        isCustomerPM: PropTypes.bool,
        customerPM: PropTypes.shape({
            commercialName: PropTypes.string,
        }),
        customerPP: PropTypes.shape({
            lastName: PropTypes.string,
            firstName: PropTypes.string,
        }),
    }),
    quotationDate: PropTypes.string,
    operations: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
        })
    ),
    prices: PropTypes.shape({
        energySavingCertificatePremium: PropTypes.number,
    }),
    status: PropTypes.string,
    uuid: PropTypes.string,
});

export const invoicePropType = PropTypes.shape({
    uuid: PropTypes.string,
    invoiceNumber: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    invoiceIssueDate: PropTypes.string,
    totalPrice: PropTypes.number,
    operations: PropTypes.arrayOf(PropTypes.shape({})),
    quotationNumber: PropTypes.string,
    personalizedText: PropTypes.string,
    organization: PropTypes.shape({}),
    energySavingCertificatePremium: PropTypes.number,
    quotationTotalPriceToPay: PropTypes.number,
    customer: PropTypes.shape({
        customerType: PropTypes.string,
        customerData: PropTypes.shape({}),
    }),
    address: PropTypes.shape({}),
    contract: PropTypes.shape({}),
    billing: PropTypes.shape({}),
    idQuotation: PropTypes.string,
    idOrganization: PropTypes.string,
    pricesPerVatRate: PropTypes.arrayOf(PropTypes.shape({})),
});

export const fieldPropTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.string,
    defaultValue: PropTypes.any,
    error: PropTypes.string,
    onChange: PropTypes.func,
};

export const accountPropType = PropTypes.shape({
    id: PropTypes.string,
    uuid: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    isVerified: PropTypes.bool,
    isComplete: PropTypes.bool,
});

export const productPropType = PropTypes.shape({
    uuid: PropTypes.string,
    category: PropTypes.string,
    subCategory: PropTypes.string,
    title: PropTypes.string,
    brand: PropTypes.string,
    certification: PropTypes.string,
    energyEfficiencyProperties: PropTypes.arrayOf(PropTypes.shape({})),
    searchableKeyWords: PropTypes.arrayOf(PropTypes.string),
    installationType: PropTypes.string,
    name: PropTypes.string,
    reference: PropTypes.string,
    textArea: PropTypes.string,
    installationDescription: PropTypes.string,
    description: PropTypes.string,
    unit: PropTypes.string,
    type: PropTypes.string,
    vatRate: PropTypes.number,
    price: PropTypes.number,
    detailsPerOperation: PropTypes.shape({}),
    productDetails: PropTypes.shape({}),
    technicalSheetUrl: PropTypes.string,
    technicalSheetId: PropTypes.string,
});

export const accountsPropType = PropTypes.arrayOf(accountPropType);

const consumerMediatorPropType = PropTypes.shape({
    _id: PropTypes.string.isRequired,
    address: PropTypes.string,
    companyName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    website: PropTypes.string,
});

const consumerMediatorsPropType = PropTypes.arrayOf(consumerMediatorPropType);

export const paginatePropTypes = PropTypes.shape({
    limit: PropTypes.number,
    skip: PropTypes.number,
    count: PropTypes.number,
    currentPage: PropTypes.number,
});

export const delegateeInvoicePropType = PropTypes.shape({
    uuid: PropTypes.string,
    reference: PropTypes.string,
    status: PropTypes.string,
    operations: PropTypes.arrayOf(
        PropTypes.shape({
            uuid: PropTypes.string,
            operationSheetName: PropTypes.string,
        })
    ),
    totalPrice: PropTypes.number,
    totalNetPrice: PropTypes.number,
});

export const delegateeInvoicesPropType = PropTypes.arrayOf(delegateeInvoicePropType);

export const pdfViewerPropType = PropTypes.shape({
    testId: PropTypes.string,
    file: PropTypes.object,
    downloadUrl: PropTypes.string,
    large: PropTypes.bool,
    full: PropTypes.bool,
    minHeight: PropTypes.string,
    toolbarHidden: PropTypes.bool,
    onOpen: PropTypes.func,
    openTitle: PropTypes.string,
    isRequired: PropTypes.bool,
});

export const applicationPages = Object.freeze({
    SUB_CONTRACTOR: 'SUB_CONTRACTOR',
    CUSTOMER: 'CUSTOMER',
    QUOTATIONS: 'QUOTATIONS',
    INVOICES: 'INVOICES',
    DOSSIERS_CEE: 'DOSSIERS_CEE',
    ORGANIZATION: 'ORGANIZATION',
    QUOTATION: 'QUOTATION',
    INVOICE: 'INVOICE',
});

const uploadDocumentPropType = PropTypes.shape({});

export const uploadDocumentsPropType = PropTypes.arrayOf(uploadDocumentPropType);

export const applicationPagePropType = PropTypes.oneOf(Object.values(applicationPages));
export { consumerMediatorsPropType, consumerMediatorPropType };
