import { HIDE_DIALOG, SHOW_DIALOG } from '../../types/ui/dialog.types';

function showSuccessDialog({ message }) {
    return { type: SHOW_DIALOG, payload: { message, successDialogShown: true } };
}

function showFailureDialog({ message }) {
    return { type: SHOW_DIALOG, payload: { message, failureDialogShown: true } };
}

function showConfirmDialog({ message, onConfirm }) {
    return { type: SHOW_DIALOG, payload: { message, confirmDialogShown: true, onConfirm } };
}

function hideDialog() {
    return { type: HIDE_DIALOG };
}

export default { showSuccessDialog, showFailureDialog, showConfirmDialog, hideDialog };
