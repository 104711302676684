import { START_REQUESTING, START_SMALL_REQUESTING, STOP_REQUESTING, STOP_SMALL_REQUESTING } from '../../types/ui/form.types';

function initState() {
    return {
        requesting: false,
    };
}

export default function (state = initState(), action) {
    const { type } = action;

    switch (type) {
        case START_SMALL_REQUESTING:
            return {
                ...state,
                small_requesting: true,
            };
        case START_REQUESTING:
            return {
                ...state,
                requesting: true,
            };

        case STOP_REQUESTING:
            return {
                ...state,
                requesting: false,
            };
        case STOP_SMALL_REQUESTING:
            return {
                ...state,
                small_requesting: false,
            };

        default:
            return state;
    }
}
