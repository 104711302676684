import { CLOSE_MESSAGE, SHOW_MESSAGE } from '../../types/ui/message.type';

const initialState = {
    messageState: {
        shown: false,
        message: null,
        type: null,
    },
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SHOW_MESSAGE:
            return {
                ...state,
                messageState: {
                    shown: true,
                    ...payload,
                },
            };
        case CLOSE_MESSAGE:
            return initialState;
        default:
            return state;
    }
};
