import { GET_CONTRACT_SUCCESS, GET_CONTRACT_FAILURE } from '../types/contract.type';

const initialState = {
    contract: {},
    loading: true,
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_CONTRACT_SUCCESS:
            return {
                ...state,
                contract: payload,
                loading: false,
            };
        case GET_CONTRACT_FAILURE:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        default:
            return state;
    }
};
