export const GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_SUCCESS = 'GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_SUCCESS';
export const GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_FAILURE = 'GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_FAILURE';

export const GET_ORGANIZATION_DOCUMENT_SUCCESS = 'GET_ORGANIZATION_DOCUMENT_SUCCESS';
export const GET_ORGANIZATION_DOCUMENT_FAILURE = 'GET_ORGANIZATION_DOCUMENT_FAILURE';

export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_FAILURE = 'GET_ORGANIZATIONS_FAILURE';

export const CONFIRM_DOCUMENT_SUCCESS = 'CONFIRM_DOCUMENT_SUCCESS';
export const CONFIRM_DOCUMENT_FAILURE = 'CONFIRM_DOCUMENT_FAILURE';

export const REJECT_DOCUMENT_SUCCESS = 'REJECT_DOCUMENT_SUCCESS';
export const REJECT_DOCUMENT_FAILURE = 'REJECT_DOCUMENT_FAILURE';

export const GET_ORGANIZATION_REQUEST = 'GET_ORGANIZATION_REQUEST';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAILURE = 'GET_ORGANIZATION_FAILURE';

export const GET_INSTALLER_REQUEST = 'GET_INSTALLER_REQUEST';
export const GET_INSTALLER_SUCCESS = 'GET_INSTALLER_SUCCESS';
export const GET_INSTALLER_FAILURE = 'GET_INSTALLER_FAILURE';

export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';

export const CREATE_ORGANIZATION_EXTENSION_REQUEST = 'CREATE_ORGANIZATION_EXTENSION_REQUEST';
export const CREATE_ORGANIZATION_EXTENSION_SUCCESS = 'CREATE_ORGANIZATION_EXTENSION_SUCCESS';
export const CREATE_ORGANIZATION_EXTENSION_FAILURE = 'CREATE_ORGANIZATION_EXTENSION_FAILURE';

export const SET_ACTIVE_ORGANIZATIONS = 'SET_ACTIVE_ORGANIZATIONS';
export const SET_INACTIVE_ORGANIZATIONS = 'SET_INACTIVE_ORGANIZATIONS';

export const ENABLE_ORGANIZATION_EXTENSION_REQUEST = 'ENABLE_ORGANIZATION_EXTENSION_REQUEST';
export const ENABLE_ORGANIZATION_EXTENSION_SUCCESS = 'ENABLE_ORGANIZATION_EXTENSION_SUCCESS';
export const ENABLE_ORGANIZATION_EXTENSION_FAILURE = 'ENABLE_ORGANIZATION_EXTENSION_FAILURE';

export const DISABLE_ORGANIZATION_EXTENSION_REQUEST = 'DISABLE_ORGANIZATION_EXTENSION_REQUEST';
export const DISABLE_ORGANIZATION_EXTENSION_SUCCESS = 'DISABLE_ORGANIZATION_EXTENSION_SUCCESS';
export const DISABLE_ORGANIZATION_EXTENSION_FAILURE = 'DISABLE_ORGANIZATION_EXTENSION_FAILURE';
