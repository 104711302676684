import { HIDE_DIALOG, SHOW_DIALOG } from '../../types/ui/dialog.types';

function initState() {
    return {
        successDialogShown: false,
        failureDialogShown: false,
    };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case SHOW_DIALOG:
            return {
                ...state,
                ...payload,
            };

        case HIDE_DIALOG:
            return initState();

        default:
            return state;
    }
}
