import { GET_CONSUMER_MEDIATORS_SUCCESS } from '../types/consumer-mediator.type';

function initState() {
    return {
        consumerMediators: [],
    };
}

export default function (state = initState, action) {
    switch (action.type) {
        case GET_CONSUMER_MEDIATORS_SUCCESS:
            return {
                consumerMediators: action.payload.consumerMediators,
            };

        default:
            return state;
    }
}
