export const GET_DOSSIERS_CEE_REQUEST = 'GET_DOSSIERS_CEE_REQUEST';
export const GET_DOSSIERS_CEE_SUCCESS = 'GET_DOSSIERS_CEE_SUCCESS';
export const GET_DOSSIERS_CEE_FAILURE = 'GET_DOSSIERS_CEE_FAILURE';

export const GET_DOSSIER_CEE_REQUEST = 'GET_DOSSIER_CEE_REQUEST';
export const GET_DOSSIER_CEE_SUCCESS = 'GET_DOSSIER_CEE_SUCCESS';
export const GET_DOSSIER_CEE_FAILURE = 'GET_DOSSIER_CEE_FAILURE';

export const SEND_DOSSIER_CEE_REQUEST = 'SEND_DOSSIER_CEE_REQUEST';
export const SEND_DOSSIER_CEE_SUCCESS = 'SEND_DOSSIER_CEE_SUCCESS';
export const SEND_DOSSIER_CEE_FAILURE = 'SEND_DOSSIER_CEE_FAILURE';

export const DOWNLOAD_DOSSIER_CEE_REQUEST = 'DOWNLOAD_DOSSIER_CEE_REQUEST';
export const DOWNLOAD_DOSSIER_CEE_SUCCESS = 'DOWNLOAD_DOSSIER_CEE_SUCCESS';
export const DOWNLOAD_DOSSIER_CEE_FAILURE = 'DOWNLOAD_DOSSIER_CEE_FAILURE';

export const REMOVE_DOSSIER_CEE_REQUEST = 'REMOVE_DOSSIER_CEE_REQUEST';
export const REMOVE_DOSSIER_CEE_SUCCESS = 'REMOVE_DOSSIER_CEE_SUCCESS';
export const REMOVE_DOSSIER_CEE_FAILURE = 'REMOVE_DOSSIER_CEE_FAILURE';

export const RECTIFY_DOSSIER_CEE_REQUEST = 'RECTIFY_DOSSIER_CEE_REQUEST';
export const RECTIFY_DOSSIER_CEE_SUCCESS = 'RECTIFY_DOSSIER_CEE_SUCCESS';
export const RECTIFY_DOSSIER_CEE_FAILURE = 'RECTIFY_DOSSIER_CEE_FAILURE';
