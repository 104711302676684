import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import 'moment/locale/fr';
import { publicRoutes } from '../routes';

import PublicRoute from './helpers/PublicRoute';

const Layout = loadable(() => import('./layouts/Layout'));

function App() {
    return (
        <BrowserRouter>
            <Switch>
                {publicRoutes.map((publicRoute) => (
                    <PublicRoute key={publicRoute.id} path={publicRoute.path} component={publicRoute.component} />
                ))}
                <Route path='/' component={Layout} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
