import {
    SHOW_VALIDATION_MODAL,
    SHOW_REJECT_MODAL,
    SHOW_DOMAINS_MODAL,
    CLOSE_MODAL,
    SHOW_CREATE_OBLIGATED_CONTRACT_MODAL,
    SHOW_CANCEL_DOCUMENT_MODAL,
    SHOW_FINALIZE_CANCELLATION_MODAL,
} from '../../types/ui/modal.type';

const initialState = {
    validationModalState: { shown: false },
    rejectModalState: { shown: false },
    cancelModalState: { shown: false },
    finalizationModalState: { shown: false },
    domainsModalState: { shown: false },
    createObligatedContractState: { shown: false },
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SHOW_VALIDATION_MODAL:
            return {
                ...state,
                validationModalState: {
                    shown: true,
                    ...payload,
                },
            };
        case SHOW_REJECT_MODAL:
            return {
                ...state,
                rejectModalState: {
                    shown: true,
                    ...payload,
                },
            };
        case SHOW_CANCEL_DOCUMENT_MODAL:
            return {
                ...state,
                cancelModalState: {
                    shown: true,
                    ...payload,
                },
            };
        case SHOW_FINALIZE_CANCELLATION_MODAL:
            return {
                ...state,
                finalizationModalState: {
                    shown: true,
                    ...payload,
                },
            };
        case SHOW_DOMAINS_MODAL:
            return {
                ...state,
                domainsModalState: {
                    shown: true,
                    ...payload,
                },
            };
        case SHOW_CREATE_OBLIGATED_CONTRACT_MODAL:
            return {
                ...state,
                createObligatedContractState: {
                    shown: true,
                    ...payload,
                },
            };
        case CLOSE_MODAL:
            return initialState;
        default:
            return state;
    }
};
