import { UPDATE_DOCUMENT_SUCCESS } from '../types/cancelledDocument.type';

const initialState = {
    document: {},
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                document: payload,
            };
        default:
            return state;
    }
};
