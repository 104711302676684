export const ADD_DOCUMENT_MPR_REQUEST = 'ADD_DOCUMENT_MPR_REQUEST';
export const ADD_DOCUMENT_MPR_SUCCESS = 'ADD_DOCUMENT_MPR_SUCCESS';
export const ADD_DOCUMENT_MPR_FAILURE = 'ADD_DOCUMENT_MPR_FAILURE';

export const GET_DOCUMENT_MPR_REQUEST = 'GET_DOCUMENT_MPR_REQUEST';
export const GET_DOCUMENT_MPR_SUCCESS = 'GET_DOCUMENT_MPR_SUCCESS';
export const GET_DOCUMENT_MPR_FAILURE = 'GET_DOCUMENT_MPR_FAILURE';

export const GET_DOCUMENTS_MPR_REQUEST = 'GET_DOCUMENTS_MPR_REQUEST';
export const GET_DOCUMENTS_MPR_SUCCESS = 'GET_DOCUMENTS_MPR_SUCCESS';
export const GET_DOCUMENTS_MPR_FAILURE = 'GET_DOCUMENTS_MPR_FAILURE';
