import { LOGIN_SUCCESS, LOGOUT, LOGIN_FAILURE, UNVERIFIED_ACCOUNT, GET_ACCOUNT_SUCCESS } from '../types/auth.type';
import storage from '../../utils/storage';

const initialState = {
    token: storage.getToken(),
    isAuthenticated: false,
    loading: true,
    user: null,
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload,
            };
        case UNVERIFIED_ACCOUNT:
        case LOGIN_FAILURE:
        case LOGOUT:
            storage.clear();
            return {
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
            };
        case GET_ACCOUNT_SUCCESS: {
            return {
                token: state.token,
                account: action.payload.account,
            };
        }
        default:
            return state;
    }
};
