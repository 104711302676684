import { GET_DELEGATEE_INVOICE_SUCCESS, GET_DELEGATEE_INVOICES_SUCCESS, VALIDATE_DELEGATEE_INVOICE_SUCCESS, SEND_DELEGATEE_INVOICE_SUCCESS } from '../types/delegateeInvoice.types';

function initState() {
    return {
        delegateeInvoices: [],
        delegateeInvoice: {},
    };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case GET_DELEGATEE_INVOICES_SUCCESS:
            return {
                ...state,
                delegateeInvoices: payload.delegateeInvoices,
            };

        case GET_DELEGATEE_INVOICE_SUCCESS:
        case VALIDATE_DELEGATEE_INVOICE_SUCCESS:
        case SEND_DELEGATEE_INVOICE_SUCCESS:
            return {
                ...state,
                delegateeInvoice: payload.delegateeInvoice,
            };

        default:
            return state;
    }
}
