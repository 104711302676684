import React from 'react';
import { Text } from '@renolib/renolib-ui-kit';
import styled from 'styled-components';
import { ReactComponent as WarningAmberIcon } from '../../../../assets/images/others/warning_amber_black.svg';

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: justify;
`;

export const WaitingForSignedQuotation = () => (
    <div>
        <Text>Le devis est en attente de la signature du bénéficiaire</Text>
    </div>
);

export const AccountCreationProcessing = () => (
    <div>
        <div>
            <Text size={'sm'}>Vous avez choisi RENOLIB comme mandataire</Text>
        </div>
        <div>
            <Text size={'sm'}>Nous contactons le bénéficiaire pour la création de son compte</Text>
        </div>
    </div>
);

export const RefusedMandate = () => (
    <div>
        <Text size={'sm'}>Le client n&apos;a pas signé le mandat</Text>
    </div>
);

//E8CF88

export const CurrentlyInstructByAnah = () => (
    <div>
        <Text size={'sm'}>Le bénéficiaire a signé son mandat.</Text>
        <Text size={'sm'}>La demande de prime est transmise à l&apos;ANAH</Text>
        <div>
            <FlexRow>
                <WarningAmberIcon />
                <Text.Label>Nous vous conseillons</Text.Label>
            </FlexRow>
            <div>
                <Text.Label>d&apos;attendre la confirmation de l&apos;ANAH pour démarrer les travaux.</Text.Label>
            </div>
        </div>
    </div>
);

export const RequestValidatedByAnah = () => (
    <div>
        <div>
            <Text size={'sm'}>L&apos;ANAH a envoyé la lettre d&apos;octroi</Text>
        </div>
        <div>
            <Text size={'sm'}>Les travaux peuvent débuter</Text>
        </div>
        <div>
            <Text size={'sm'}>A la fin des travaux vous pourrez créer et finaliser votre facture</Text>
        </div>
    </div>
);

export const RequestRefuseByAnah = () => (
    <div>
        <div>
            <Text size={'sm'}>L&apos;ANAH a refusé votre demande de prime. Pour plus de précision, veuillez contacter notre service client.</Text>
        </div>
        <div>
            <FlexRow>
                <WarningAmberIcon />
                <Text.Label size={'sm'}>Vous pouvez effectuer </Text.Label>
            </FlexRow>
            <div>
                <Text.Label>les travaux mais ils ne seront pas financés par l&apos;ANAH</Text.Label>
            </div>
        </div>
    </div>
);

export const ProcessingControl = () => (
    <div>
        <div>
            <Text size={'sm'}>Nous avons bien reçu votre facture.</Text>
        </div>
        <div>
            <Text size={'sm'}>Votre dossier est en cours de contrôle.</Text>
        </div>
        <div>
            <Text size={'sm'}>Pour permettre l&apos;avancée de la prime de votre dossier, veuillez fournir les photos avant/après du chantier.</Text>
        </div>
    </div>
);

export const ProcessingPaimentByRenolib = () => (
    <div>
        <div>
            <Text size={'sm'}>La facture est déposée à l&apos;ANAH</Text>
        </div>
        <div>
            <Text size={'sm'}>Le paiement est en cours</Text>
        </div>
    </div>
);

export const PaidByRenolib = () => (
    <div>
        <Text size={'sm'}>Rénolib vous a reversé la prime</Text>
    </div>
);

export const RequestBalance = () => (
    <div>
        <div>
            <Text size={'sm'}>La facture est déposée à l&apos;ANAH.</Text>
        </div>
        <div>
            <Text size={'sm'}>Le paiement est en cours de demande auprès de l&apos;ANAH</Text>
        </div>
    </div>
);
export const PaidByANAH = () => (
    <div>
        <Text size={'sm'}>L&apos;ANAH a reversé la prime au bénéficiaire</Text>
    </div>
);
