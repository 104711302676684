import { GET_SUBCONTRACTOR_RGE_SUCCESS, GET_SUBCONTRACTOR_RGE_FAILURE } from '../types/subcontractor.type';

const initialState = {
    document: {},
    loading: true,
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_SUBCONTRACTOR_RGE_SUCCESS:
            return {
                ...state,
                document: payload,
                loading: false,
            };
        case GET_SUBCONTRACTOR_RGE_FAILURE:
            return {
                ...state,
                document: {},
                loading: false,
            };
        default:
            return state;
    }
};
