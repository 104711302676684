import { GET_ANALYTICS_SUCCESS } from '../types/analytics.type';

function initState() {
    return {
        analytics: {},
    };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: payload.analytics,
            };

        default:
            return state;
    }
}
