import jwt_decode from 'jwt-decode';
import { isValidValue } from './index';

const TOKEN = 'token';
export const ORGANIZATION_UUID_KEY = 'ORGANIZATION_UUID_KEY';

const getToken = () => localStorage.getItem(TOKEN);

const setToken = (token) => localStorage.setItem(TOKEN, token);

const isAuthenticated = () => !!localStorage.getItem(TOKEN);

const clear = () => localStorage.clear();

const setOrganizationUUID = ({ organizationUUID } = {}) => localStorage.setItem(ORGANIZATION_UUID_KEY, organizationUUID);

const getOrganizationUUID = () => localStorage.getItem(ORGANIZATION_UUID_KEY);

const isComplete = () => isValidValue(getOrganizationUUID());

const getCurrentIdAccount = () => jwt_decode(getToken()).sub;

export default {
    getToken,
    setToken,
    isComplete,
    isAuthenticated,
    getOrganizationUUID,
    setOrganizationUUID,
    clear,
    getCurrentIdAccount,
};
