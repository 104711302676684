import {
    GET_ORGANIZATIONS_AND_THEIR_PRODUCTS_SUCCESS,
    GET_ORGANIZATIONS_AND_THEIR_PRODUCTS_FAILURE,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAILURE,
    GET_ORGANIZATIONS_AND_THEIR_PRODUCTS_REQUEST,
} from '../types/productVerification.types';

const initialState = {
    organizationsAndTheirProducts: [],
    product: {},
    loading: true,
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ORGANIZATIONS_AND_THEIR_PRODUCTS_REQUEST:
            return {
                ...state,
                organizationsAndTheirProducts: [],
                loading: true,
            };
        case GET_ORGANIZATIONS_AND_THEIR_PRODUCTS_SUCCESS:
            return {
                ...state,
                organizationsAndTheirProducts: payload,
                loading: false,
            };
        case GET_ORGANIZATIONS_AND_THEIR_PRODUCTS_FAILURE:
            return {
                ...state,
                organizationsAndTheirProducts: [],
                loading: false,
            };
        case GET_PRODUCT_SUCCESS:
            return {
                ...state,
                product: payload,
                loading: false,
            };
        case GET_PRODUCT_FAILURE:
            return {
                ...state,
                product: {},
                loading: false,
            };
        default:
            return state;
    }
};
