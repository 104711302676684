import axios from 'axios';

import storage from '../utils/storage';
import setToken from '../utils/setToken';
import formActions from '../store/actions/ui/form.actions';
import dialogActions from '../store/actions/ui/dialog.actions';

if (storage.getToken()) setToken(storage.getToken());

const endpointV1 = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_ENDPOINT,
});

const endpointV2 = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_V2_ENDPOINT,
});

const parseError = (error) => {
    let errorMessage = error.message;
    if (error.response && error.response.data) errorMessage = error.response.data.message;

    return typeof errorMessage !== 'string' ? 'Erreur serveur' : errorMessage;
};

function getAuthHeaders() {
    return { Authorization: `Bearer ${storage.getToken()}` };
}

const jsonContentType = { 'Content-Type': 'application/json' };

function handleHttpErrorResponse({ dispatch, errorType, showErrorDialog = true } = {}) {
    return (error) => {
        const message = parseError(error);
        dispatch({ type: errorType, payload: { message } });
        dispatch(formActions.stopRequesting());
        if (showErrorDialog) dispatch(dialogActions.showFailureDialog({ message }));
    };
}

function setAuthorizationHeader({ token }) {
    [endpointV1, endpointV2].forEach((endpoint) => {
        endpoint.defaults.headers['Authorization'] = `Bearer ${token}`;
    });
}

function getAuthHeadersWithFormDataContentType() {
    return {
        ...getAuthHeaders(),
        'Content-Type': 'application/x-www-form-urlencoded',
    };
}

function getAuthHeadersWithJSONContentType() {
    return {
        ...getAuthHeaders(),
        'Content-Type': 'application/json',
    };
}

export {
    endpointV1,
    endpointV2,
    parseError,
    getAuthHeaders,
    jsonContentType,
    handleHttpErrorResponse,
    setAuthorizationHeader,
    getAuthHeadersWithFormDataContentType,
    getAuthHeadersWithJSONContentType,
};
