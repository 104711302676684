import React from 'react';
import { Flex, Text } from '@renolib/renolib-ui-kit';

import { operationsPropType } from '../../utils/propTypeSchemas';

export default function Operations({ operations = [] }) {
    return operations.length > 0 ? (
        <Flex flexDirection='column' className='operations'>
            {operations.map(({ key, name }) => (
                <Text.Label key={key}>{name}</Text.Label>
            ))}
        </Flex>
    ) : (
        <Text.Label>Aucune opération</Text.Label>
    );
}

Operations.propTypes = {
    operations: operationsPropType,
};
