import { CLOSE_MESSAGE, SHOW_MESSAGE } from '../../types/ui/message.type';

const closeMessage = () => ({ type: CLOSE_MESSAGE });

const showMessage = ({ message, type, timeout = 5000 }) => (dispatch) => {
    dispatch({ type: SHOW_MESSAGE, payload: { message, type } });

    setTimeout(() => dispatch(closeMessage()), timeout);
};

export default { showMessage, closeMessage };
