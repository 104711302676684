import { endpointV2, getAuthHeaders } from '../../api';
import storage from '../../utils/storage';

function getBaseUrl() {
    return `/organizations/${storage.getOrganizationUUID()}/dossiers-maPrimeRenov`;
}

export const getDossiersMaPrimeRenovCount = async (query) => {
    return endpointV2.get(`${getBaseUrl()}/count`, {
        headers: getAuthHeaders(),
        params: {
            ...query,
            getAll: true,
        },
    });
};

export const getDossiersMaPrimeRenov = async (options) => {
    return endpointV2.get(getBaseUrl(), {
        headers: getAuthHeaders(),
        params: { ...options, getAll: true },
    });
};

export const getDossierMaPrimeRenov = async (maPrimeRenovId) => {
    return endpointV2.get(`${getBaseUrl()}/${maPrimeRenovId}`, {
        headers: getAuthHeaders(),
    });
};
