import { ADD_DOCUMENT_MPR_SUCCESS, GET_DOCUMENTS_MPR_SUCCESS } from '../types/dossierMPR.type';

function initState() {
    return { dossiersMPR: null, dossierMPR: null };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_DOCUMENT_MPR_SUCCESS:
            return {
                ...state,
                dossierMPR: payload,
            };
        case GET_DOCUMENTS_MPR_SUCCESS:
            return {
                ...state,
                dossiersMPR: payload,
            };

        default:
            return state;
    }
}
