import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import '@renolib/renolib-ui-kit/dist/index.css';

import App from './components/App';
import store from './store';
import GlobalStyle from './styles/index';

import Message from './components/helpers/Message';

ReactDOM.render(
    <Provider store={store}>
        <GlobalStyle />
        <Message />
        <App />
    </Provider>,
    document.getElementById('root')
);
