import { GET_ACCOUNTS_REQUEST, GET_ACCOUNTS_SUCCESS, ACTIVATE_ACCOUNT_SUCCESS } from '../types/accounts.types';

function initState() {
    return { accounts: [] };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ACCOUNTS_REQUEST:
            return {
                ...state,
                accounts: [],
            };

        case GET_ACCOUNTS_SUCCESS:
            return {
                ...state,
                accounts: payload.accounts,
            };

        case ACTIVATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                accounts: state.accounts.map((account) => {
                    if (account.id === payload.account.id) return payload.account;

                    return account;
                }),
            };

        default:
            return state;
    }
}
