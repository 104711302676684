import { START_REQUESTING, START_SMALL_REQUESTING, STOP_REQUESTING, STOP_SMALL_REQUESTING } from '../../types/ui/form.types';

function startRequesting() {
    return { type: START_REQUESTING };
}

function startSmallRequesting(params = {}) {
    return { type: START_SMALL_REQUESTING };
}

function stopSmallRequesting(params = {}) {
    return { type: STOP_SMALL_REQUESTING };
}
function stopRequesting() {
    return { type: STOP_REQUESTING };
}

export const executeAnyTypeOfRequest = (action, onSuccess, startAction = startRequesting, stopAction = stopRequesting) => {
    return (dispatch) => {
        return action()
            .then(({ data }) => {
                if (onSuccess) onSuccess();
                return data;
            })
            .catch((error) => {
                console.error({ error });
            });
    };
};

export default { startRequesting, stopRequesting, executeAnyTypeOfRequest, startSmallRequesting, stopSmallRequesting };
