import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import storage from '../../utils/storage';

const PublicRoute = ({ component: Component, path, exact }) => {
    return <Route path={path} exact={exact} render={(props) => (storage.isAuthenticated() ? <Redirect to='/verification-de-documents' /> : <Component {...props} />)} />;
};

PublicRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
};

export default PublicRoute;
